
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "report-claims",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Report Claims", [
        "Cooperative",
        "Members",
        "Claims"
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Cooperative: Report Members Claims | " + process.env.VUE_APP_TITLE;
  }
});
